// Algolia  
var algolia = algoliasearch(app.algolia.app_id, app.algolia.api_key);

var algoliaHelper, hitTemplate, statsTemplate, paginationTemplate;
var $inputField, $hits, $stats, $pagination;

$stats = $('#stats');
$pagination = $('#pagination');

function renderHits(hits, container) {
  var hitsHtml = '';
  
  for (var i = 0; i < hits.length; ++i) {
    hitsHtml += templates[hits[i].listing.group].render(hits[i], {
      social: templates.social
    });
  }

  if (hits.length === 0) hitsHtml = templates['empty-results'].render();
  container.html(hitsHtml);

}

function renderPagination(content) {
  // If no results
  if (content.hits.length === 0) {
    $pagination.empty();
    return;
  }

  // Process pagination
  var pages = [];
  if (content.page > 5) {
    pages.push({ current: false, number: 1 });
    pages.push({ current: false, number: '...', disabled: true });
  }
  for (var p = content.page - 5; p < content.page + 5; ++p) {
    if (p < 0 || p >= content.nbPages) {
      continue;
    }
    pages.push({ current: content.page === p, number: p + 1 });
  }
  if (content.page + 5 < content.nbPages) {
    pages.push({ current: false, number: '...', disabled: true });
    pages.push({ current: false, number: content.nbPages });
  }
  var pagination = {
    pages: pages,
    prev_page: content.page > 0 ? content.page : false,
    next_page: content.page + 1 < content.nbPages ? content.page + 2 : false
  };
  // Display pagination
  $pagination.html(paginationTemplate.render(pagination));
}

function renderStats(content) {
  var stats = {
    nbHits: content.nbHits,
    processingTimeMS: content.processingTimeMS,
    nbHits_plural: content.nbHits !== 1
  };
  $stats.html(statsTemplate.render(stats));
}

function setURLParams(state) {
  var urlParams = '#';
  var currentQuery = state.query;
  urlParams += 'q=' + encodeURIComponent(currentQuery);
  var currentPage = state.page + 1;
  urlParams += '&page=' + currentPage;
  for (var facetRefine in state.facetsRefinements) {
    urlParams += '&' + encodeURIComponent(facetRefine) + '=' + encodeURIComponent(state.facetsRefinements[facetRefine]);
  }
  for (var disjunctiveFacetrefine in state.disjunctiveFacetsRefinements) {
    for (var value in state.disjunctiveFacetsRefinements[disjunctiveFacetrefine]) {
      urlParams += '&' + encodeURIComponent(disjunctiveFacetrefine) + '=' + encodeURIComponent(state.disjunctiveFacetsRefinements[disjunctiveFacetrefine][value]);
    }
  }
  location.replace(urlParams);
}

function initWithUrlParams() {
  var sPageURL = location.hash;
  if (!sPageURL || sPageURL.length === 0) {
    return true;
  }

  var sURLVariables = sPageURL.split('&');
  if (!sURLVariables || sURLVariables.length === 0) {
    return true;
  }

  var query = decodeURIComponent(sURLVariables[0].split('=')[1]);
  if (!query.length) {
    return true;
  }

  $inputField.val(query);
  algoliaHelper.setQuery(query).search();
  for (var i = 2; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    var facet = decodeURIComponent(sParameterName[0]);
    var value = decodeURIComponent(sParameterName[1]);
    algoliaHelper.toggleRefine(facet, value, false);
  }
  // Page has to be set in the end to avoid being overwritten
  var page = decodeURIComponent(sURLVariables[1].split('=')[1]) -1;
  algoliaHelper.setCurrentPage(page).search();
}

function initAlgoliaSearch(index, params) {
  $inputField = $('#q');

  // Templates binding
  statsTemplate = templates.stats;
  paginationTemplate = templates.pagination;

  algoliaHelper = algoliasearchHelper(algolia, index, params);

  initWithUrlParams();

  // Input binding
  $inputField
    .on('keyup', function() {
      var query = $inputField.val();
      algoliaHelper.setQuery(query).search();
    });

  algoliaHelper.on('result', function(content, state) {
    renderStats(content);
    renderHits(content.hits, $('#hits'));
    renderPagination(content);
    $('.results').show();
  });

  $(document).on('click', '.gotoPage', function() {
    algoliaHelper.setCurrentPage(+$(this).data('page') - 1).search();
    $('html, body').animate({scrollTop:$('#q').offset().top}, '500', 'swing');
    return false;
  });

  algoliaHelper.on('change', setURLParams);
}