
function getMiles(i) {
  return parseInt(i*0.000621371192).toFixed(1);
}

function getMeters(i) {
  return parseInt(i*1609.344);
}

function scrollToHash(hash) {
  var target = $(hash);
  target = target.length ? target : $('[name=' + hash.slice(1) +']');
  if (target.length) {
    $('html,body').animate({
      scrollTop: target.offset().top
    }, 400);
    return false;
  }
}

function loading(message) {
  $('body').append(templates.loading.render({message: message})).addClass('loading');
}

function loaded() {
  $('body').removeClass('loading');
  $('.loading-overlay').remove();
}

function loadRaty() {
  $('.rating').raty({ 
    starType: 'i', 
    score: function() {
      return $(this).attr('data-score');
    },
    hints: [null, null, null, null, null],
    readOnly: true
  });
}

$(document).ready(function () {

  var csrfToken = $('meta[name="csrf-token"]').attr('content');

  $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': csrfToken
      }
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.scrollup').fadeIn();
    } else {
      $('.scrollup').fadeOut();
    }
  });

  loadRaty();

  $('.scrollup').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });

  $('.vertical-menu, .drop-vertical-menu').hover(function() {
    $('.drop-vertical-menu').addClass('focus');
  }, function() {
    $('.drop-vertical-menu').removeClass('focus');
  });

  $('.drop-vertical-menu .menu-left a:first').addClass('focus');
  $('.menu-container:first').addClass('focus');

  $('.drop-vertical-menu .menu-left a').hover(function() {
    $('.menu-container').removeClass('focus');
    $('.menu-left a').removeClass('focus');

    var menu = $(this).data('menu');
    $('.' + menu + '-menu').addClass('focus');
    $('[data-menu='+menu+']').addClass('focus');
  });

  $('.expander-trigger').click(function(){
    $(this).toggleClass("expander-hidden");
  });

  $(function() {

    if (app.visitorLocation.state) {
      $('#location-state-name').text(app.visitorLocation.state.name);
      $('#location-state-code').text(app.visitorLocation.state.code);
      $('#location-country').text(app.visitorLocation.country);
    }

    $('a[href*=\\#]:not([href=\\#]):not(.tab-link):not([data-no-scroll])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        scrollToHash(this.hash);
      }
    });

    if (window.location.hash) {
      var hash = '#'+window.location.hash.replace(/ /g,'-').replace(/[^\w-]+/g,'');
      var block = $(hash);
      if (block.length) {
        var tab = $('.tab-link[href=\\'+hash+']');
        if (tab.length) {
          tab.trigger('click');
          scrollToHash(hash);
        }
      }
    }

    $('a.facebook-share').on('click', function(e) {
      e.preventDefault();
      
      FB.ui(
       {
        method: 'share',
        href: $(this).attr('href')
      }, function(response) {
        if (response && ! response.error_code) {
          swal('Success!', 'Share successfull.', 'success');
        } else {
          swal('Oops!', 'Error while sharing. Please try again.', 'error');
        }
      });

    });

    $(document).on('submit', 'form.listing-contact', function(e) {
      e.preventDefault();

      loading('Sending...');

      $.post($(this).attr('action'), $(this).serialize())
        .done(function(result, status, xhr) {
          swal('Success!', 'Message was successfully sent.', 'success');

        })
        .fail(function(xhr, status, error) {
          var response = JSON.parse(xhr.responseText);
          var errorString = '';
          $.each(response, function( key, value) {
            errorString += value + ' ';
          });
          
          swal('Oops!', 'There was an error. '+errorString, 'error');
        })
        .always(function() {
          loaded();
        });
    });

    $('input#contact-modal').prop('checked', false).change();

  });

  $(document).on('click', '[data-listing] a.contact', function(e) {
    
    var listing = $(this).parents('[data-listing]');

    var listingId = listing.data('listing');
    var name = listing.data('listing-name');


    $.magnificPopup.open({
      items: {
        src: Hogan.compile($('#contact-modal-template').text()).render({
          name: name,
          listingId: listingId
        }),
        type: 'inline'
      }
    });


    //$('.contact-modal .name').text(name);
    //$('.contact-modal input[name=listing_id]').val(listingId);

    $('.contact-modal form').get(0).reset();

  });

  $(document).on('click', '[data-listing] a:not([data-no-track]):not([href^=\\#])', function(e) {
    e.preventDefault();

    var listingId = $(this).parents('[data-listing]').data('listing');
    var destination = $(this).attr('href');

    $.post(app.baseUrl+'/listing/action', {
      listing_ids: [listingId],
      type: 'click',
      path: window.location.pathname,
      destination: destination
    });

    if (typeof ga != 'undefined') {
      ga('send', 'event', 'listing', 'click', listingId);
    }

    setTimeout(function() {

      window.location = destination;
    
    }, 350);

  });

  if ($('[data-listing]').length) {
    var renderedIds = [];

    $('[data-listing]').each(function(i, listing) {
      renderedIds.push($(listing).data('listing'));
    });

    if (renderedIds.length) {
      //$.post(app.baseUrl+'/listing/action', {listing_ids: renderedIds, type: 'impression', path: window.location.pathname});
    }
  }

  if ($('[data-featured-listing]').length) {

    $('[data-featured-listing]').each(function(i, el) {
      var container = $(el);
      var url = container.data('featured-listing');

      $.post(url, {path: window.location.pathname})
        .done(function(result) {
          if (result.success && result.hits.length) {
            renderHits(result.hits, container);
          }
          else {
            container.html(templates.signup.render({url: result.actionUrl}));
          }
        })
        .fail(function(xhr, status, error) {
          swal('Oops!', error, 'error');
        });

    });
  }

  function calculateFadeIn(el, offset) {
    var elementTopToPageTop = el.offset().top;
    var windowTopToPageTop = $(window).scrollTop();
    var windowInnerHeight = window.innerHeight;
    var elementTopToWindowTop = elementTopToPageTop - windowTopToPageTop;
    var elementTopToWindowBottom = windowInnerHeight - elementTopToWindowTop;
    var distanceFromBottomToAppear = offset;

    if (elementTopToWindowBottom > distanceFromBottomToAppear) {
      el.addClass('js-fade-element-show');
    }
    else if (elementTopToWindowBottom < 0) {
      el.removeClass('js-fade-element-show');
      el.addClass('js-fade-element-hide');
    }
  }

  function limitChars()
  {
    var el = $("[data-limit-chars]");
    var limit = el.data('limit-chars');
    var actual = el.val().length;
    var charCount = el.next('.char-count');

    el.data('limit-count', actual);

    if (actual >= limit) {
      el.val(el.val().substring(0, limit));
    }

    el.trigger('limit.change');

    charCount.find('em').text((limit - el.val().length));
  }

  if ($("[data-limit-chars]").length) {
    limitChars();

    $("[data-limit-chars]").on('keyup', function() {
      limitChars();
    });
  }

  $(document).on('click.confirmation', '[data-confirmation]', function(e) {
    var el = $(this);
    
    if (el.data('confirmed')) {
      var method, form, response;
      if (method = el.data('confirmation-method')) {
        form = $('<form/>').attr({
          action: el.attr('href'),
          method: method
        })
        .append('<input type="hidden" name="_token" value="' + csrfToken + '"/>');

         form.appendTo($(document.body)).submit();
      }
      else {
        return window.location.href = el.attr('href');
      }
    }

    e.preventDefault();

    swal({
      title: "Are you sure?",
      text: $(this).data('confirmation'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, I'm sure!",
      closeOnConfirm: false
    },
    function(isConfirm){
      if (isConfirm) {
        el.data('confirmed', true);

        el.trigger('click');
      }
    });

  });

  // Dropdown Select
  $(document).on('click', ".dropdown-button", function() {
    var $button, $menu;
    $button = $(this);
    $menu = $button.siblings(".dropdown-menu");
    $menu.toggleClass("show-menu");
    $menu.children("li").click(function() {
      $menu.removeClass("show-menu");
      $button.html($(this).html());
    });
  });

  // Expander
  var expanderTrigger = document.getElementById("js-expander-trigger");
  var expanderContent = document.getElementById("js-expander-content");

  $('#js-expander-trigger').click(function(){
    $(this).toggleClass("expander-hidden");
  });

  var isMobile = window.matchMedia("only screen and (max-width: 760px)");

  // Tabs
	$('.accordion-tabs').each(function(index) {
		$(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
	});
	$('.accordion-tabs').on('click', 'li > a.tab-link', function(event) {
    event.preventDefault();

    var accordionTabs = $(this).closest('.accordion-tabs');

    accordionTabs.find('.is-open').removeClass('is-open').hide();
    $(this).next().toggleClass('is-open').toggle();

    accordionTabs.find('.is-active.tab-link').removeClass('is-active');
    $(this).addClass('is-active');

    $('html,body').animate({
      scrollTop: $(this).offset().top
    }, 400);

	});

	// Animations
	var animationClasses = 'animated alternate iteration zoomOut';
  var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';

  $('.animate-trigger').on('click',function() {
    $('.animate-target').addClass(animationClasses).one(animationEnd,function() {
      $(this).removeClass(animationClasses);
    });
  });

  // Modals
  $(document).on("change", '.modal-state', function() {
    if ($(this).is(":checked")) {
      $("body").addClass("modal-open");
    } else {
      $("body").removeClass("modal-open");
    }
  });
  
  $(document).on("click", ".modal-fade-screen, .modal-close", function() {
    $(".modal-state:checked").prop("checked", false).change();
  });
  
  $(document).on("click", ".modal-inner", function(e) {
    e.stopPropagation();
  });

  // Sliding Panel
  $('.sliding-panel-button').on('click touchstart', function(e) {
    var panelId = $(this).attr('href');
    $('.sliding-panel-fade-screen,.sliding-panel-close').data('visible-panel', panelId);
    $('.sliding-panel-fade-screen').add(panelId).toggleClass('is-visible');
    e.preventDefault();
  });

  $('.sliding-panel-fade-screen,.sliding-panel-close').on('click touchstart', function(e) {
    $('.sliding-panel-fade-screen,' + $(this).data('visible-panel')).toggleClass('is-visible');
  });

  // Tabs
  $(".js-vertical-tab-content").hide();
  $(".js-vertical-tab-content:first").show();

  /* if in tab mode */

  $(".js-vertical-tab").click(function(event) {
    event.preventDefault();

    $(".js-vertical-tab-content").hide();
    var activeTab = $(this).attr("rel");
    $("#"+activeTab).show();

    $(".js-vertical-tab").removeClass("is-active");
    $(this).addClass("is-active");

    $(".js-vertical-tab-accordion-heading").removeClass("is-active");
    $(".js-vertical-tab-accordion-heading[rel^='"+activeTab+"']").addClass("is-active");
  });

  /* if in accordion mode */

  $(".js-vertical-tab-accordion-heading").click(function(event) {
    event.preventDefault();

    $(".js-vertical-tab-content").hide();
    var accordion_activeTab = $(this).attr("rel");
    $("#"+accordion_activeTab).show();

    $(".js-vertical-tab-accordion-heading").removeClass("is-active");
    $(this).addClass("is-active");

    $(".js-vertical-tab").removeClass("is-active");
    $(".js-vertical-tab[rel^='"+accordion_activeTab+"']").addClass("is-active");
  });

});